import Vue from 'vue'
import App from './App.vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import router from "@/Pages/Public/router";
import '@/assets/styles/main.sass';
import '@/assets/styles/fonts/geometriaStyle.scss';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

Vue.config.productionTip = false;
new Vue({
  vuetify: new Vuetify({}),
  router,
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  render: h => h(App),
}).$mount('#app');
