<template>
<div class="telegram-banner">
  <div class="telegram-banner__wrapper">
    <div>Актуальная и оперативная информация для сотрудников организаций в нашем Telegram-канале</div>
    <button @click="clickTelegramBannerLink">ПОДПИСАТЬСЯ</button>
  </div>
</div>
</template>

<script>
export default {
  name: "TelegramBanner",
  methods: {
    clickTelegramBannerLink(){
      const link = document.createElement('a');
      link.href = 'https://t.me/stipendiatrussia';
      link.target = '__blank';
      link.click();
      // https://t.me/+VAZ2lg0cFDsyMjky
    }
  },
}
</script>

<style lang="sass" scoped>
.telegram-banner
  display: flex
  align-items: center
  justify-content: center
  background-color: #214DA1
  height: 66px
  color: white
  font-family: 'Inter'
  font-style: normal
  font-weight: 400
  font-size: 20px
  line-height: 24px
  @media screen and (max-width: 450px)
    padding: 5px
    //height: 46px
    max-height: 66px
    font-size: 14px
    line-height: 14px
  // border-bottom: 1px solid #B5C7D3;

  .telegram-banner__wrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: space-between
    width: 100%
    max-width: 1180px
    button
      padding: 14px
      background: rgba(255, 255, 255, 0.15)
      border: 0.75px solid #FFFFFF
      box-sizing: border-box
      border-radius: 10px

    button:hover
      background: rgba(255, 255, 255, 0.3)

      @media screen and (max-width: 450px)
        padding: 5px

</style>