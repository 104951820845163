<template>
  <div id="app" class="app" data-app>
    <Header/>
    <telegram-banner/>
    <main class="main-app">
      <router-view>
      </router-view>
    </main>
    <Footer class="footer-app"/>
  </div>
</template>

<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import TelegramBanner from "@/components/TelegramBanner";

export default {
  name: 'App',
  components: {
    TelegramBanner,
    Header,
    Footer,
  }
}
</script>

<style lang="sass">
.app
  flex: 1 1 auto
  -webkit-backface-visibility: hidden
  backface-visibility: hidden
  display: flex
  flex-direction: column
  min-height: 100vh
  max-width: 100%
  position: relative

.main-app
  display: flex
  flex-direction: column
  flex: 1 0 auto
  width: 100% !important
  align-items: center
  transition: .2s cubic-bezier(.4, 0, .2, 1)
  
  > article
    width: 100% !important
    display: flex
    flex-direction: column
    flex: 1 0 auto

.footer-app
  align-items: center
  display: flex
  flex: 0 1 auto !important
  flex-wrap: wrap
  width: 100%
  padding: 6px 16px
  position: relative
  transition-duration: .2s
  transition-property: background-color, left, right
  transition-timing-function: cubic-bezier(.4, 0, .2, 1)

</style>
